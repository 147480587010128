<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button variant="outline-success" @click="newPurchaseOrder">
          + CREATE
        </b-button>
      </template>
    </header-slot>

    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'logistic-bills-purchase-order' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        PURCHASE ORDER
      </b-nav-item>
      <!-- <b-nav-item
        :to="{ name: 'application-completed' }"
        exact
        :exact-active-class="statusApp == 2 ? 'active' : ''"
        @click="refreshTable"
        :link-classes="['px-3', bgTabsNavs]"
        >COMPLETED
      </b-nav-item> -->
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" ref="routerView" />
    </b-card>
  </div>
</template>

<script>
export default {
  methods: {
    newPurchaseOrder() {
        this.$refs.routerView.newPurchaseOrder();
    },
  },
};
</script>

<style>
</style>